// import { graphql, useStaticQuery} from "gatsby";
// import { graphql, StaticQuery } from "gatsby";
// import Img from "gatsby-image";
import * as React from "react";
// @ts-ignore
import classes from "./Image.module.css";
// import image from "../../../images/purchase"
const GenericImage = (props) => {
    return (<img className={props.classes || classes.small} alt={props.alt} src={props.img}/>);
};
export default GenericImage;
