import React from "react";
import HopSale from "../scenes/hopSale/HopSale";
import SEO from "../components/seo";
import { keywords } from "../content/keywords";
export default () => {
    return (<>
      <SEO title={"Amarillo® Hop"} keywords={keywords}/>
      <HopSale />
    </>);
};
