import { useStaticQuery, graphql } from "gatsby";
export default () => {
    const data = useStaticQuery(graphql `  
    query {
      content: file(
        relativePath: { eq: "images/hop_varieties/VGFCertIcons.png" }
      ) {
        childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
        }
      }
    }    
  `);
    return data;
};
