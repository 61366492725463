import React from "react";
import s from "./Content.module.scss";
const Content = ({ imgURL }) => {
    return (<section className={s.box}>
      <div className={s.flex}>
        <div className={s.textBox2}>       
        <img className={s.img2} src={imgURL.src} alt=""/>
        </div>
      <div className={s.textBox3}>
        <h2 className={s.title}>Hop Purchasing and Distribution</h2>
        <p className={s.text}>
        Amarillo® and other high quality aroma hops grown by Virgil
Gamache Farms (VGF) are for sale domestically and
internationally through our strong distribution network and
direct sales.          
        </p>
      </div>
      </div>
    </section>);
};
export default Content;
