import * as React from "react";
// @ts-ignore
import classes from "./Modal.module.css";
import s from "./Modal.module.scss";
import GenericImage from "../../../shared/image/Image";
const ImageContainer = (props) => {
    const { alt, filename, to, img } = props;
    return (<div className={classes.imageLink}>
      <a href={to} target="_blank" rel="noopener noreferrer">
        <GenericImage alt={alt} filename={filename} classes={classes.image} img={img}/>
      </a>
    </div>);
};
const Modal = (props) => {
    const { show, close, data } = props;
    if (!show) {
        return <></>;
    }
    const { country, hops } = data;
    return (<div className={classes.backdrop} onClick={() => close()}>
      <div className={classes.modal}>
        <h2 className={s.modalHeader}>
          {country} Hop and Brewing
          <br />
          Supply Distributors
        </h2>
        <div className={classes.modalContent}>
          <div className={classes.imageContainer}>
            {hops.map((item, index) => {
        return <ImageContainer key={index} {...item}/>;
    })}
          </div>
        </div>
        <div className={classes.modalFooter}>
          <button className={s.link} onClick={() => close()}>
            BACK
          </button>
        </div>
      </div>
    </div>);
};
export default Modal;
