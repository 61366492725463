import React from "react";
import Hero from "../../components/hero";
import SectionSeparator from "../../components/sectionSeparator";
import useGetImage from "./useGetImage";
import useGetImage2 from "./useGetImage2";
import { Content } from "./components/content";
import Brands from "./components/brands";
import s from "./HopSale.module.scss";
const HopSale = () => {
    const { hero } = useGetImage();
    const { content } = useGetImage2();
    // const { amariloNodes, otherNodes } = getTableData()
    return (<>
      <Hero imgURL={hero.childImageSharp.fluid}/>
      <SectionSeparator />
      <div className={s.wrap}>
        <Content imgURL={content.childImageSharp.fluid}/>
        
        <Brands />
      </div>
    </>);
};
export default HopSale;
