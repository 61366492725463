import React, { useState } from "react";
import s from "./Brands.module.scss";
import Modal from "./components/modal/Modal";
import DistributorItem from "./components/distributor/Distributor";
import { distributorItemsFirstRow } from "./Distributor.items";
const Brands = () => {
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState({ country: "", hops: undefined });
    const handleClick = (props) => {
        if (props) {
            setModalData({
                country: props.country,
                hops: props.hops,
            });
        }
        setOpen(!open);
    };
    return (<section className={s.brands}>
      <div className={s.box}>
      <Modal show={open} close={handleClick} data={modalData}/>
      
          
            {distributorItemsFirstRow.map((distributor, index) => {
        return (<DistributorItem key={index} country={distributor.country} hops={distributor.hops} backgroundImage={distributor.backgroundImage} hopBug={distributor.hopBug} handleModal={handleClick}/>);
    })}    
          
         
            </div>
     
    </section>);
};
export default Brands;
