import * as React from "react";
import s from "../../Brands.module.scss";
const DistributorItem = (props) => {
    const { country, hops, handleModal, backgroundImage } = props;
    const handleClick = () => {
        handleModal({ country, hops });
    };
    return (<table onClick={handleClick}>
                <tbody>
                    <tr>
                    <td><img className={s.img} src={backgroundImage} alt={country}/>
                </td>
                    </tr>
                    <tr className={s.tableLine}>
                  <td>{country}</td>                 
                  </tr>
                  <tr className={s.tableLineMore}>
                  <td>More Info</td>
                  </tr>
                  </tbody>      
                  </table>);
};
export default DistributorItem;
