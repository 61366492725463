// @ts-ignore
import AmericaBackground from "../../images/new-flags/USA.jpg";
// @ts-ignore
import AfricaBackground from "../../images/new-flags/Africa.jpeg";
// @ts-ignore
import CentralAmericaBackgound from "../../images/new-flags/CentralAmerica.jpeg";
// @ts-ignore
import AustraliaBackground from "../../images/new-flags/Australia.jpg";
// @ts-ignore
import CanadaBackground from "../../images/new-flags/Canada.jpg";
// @ts-ignore
import EUBackground from "../../images/new-flags/EU.jpg";
// @ts-ignore
import AsianBackground from "../../images/new-flags/AsianPacific.jpg";
// @ts-ignore
import HopImage from "../../images/Hop.png";
// @ts-ignore
import SABackground from "../../images/new-flags/SouthAmerica.jpg";
// @ts-ignore
import Bathhaas from "../../images/purchase/white-bg/Bathhaas.png";
// @ts-ignore
import Bitani from "../../images/purchase/white-bg/Bitani.png";
// @ts-ignore
import BrewCulture from "../../images/purchase/white-bg/BrewCulture.png";
// @ts-ignore
import BrewNation from "../../images/purchase/white-bg/BrewNation.png";
// @ts-ignore
import BSG from "../../images/purchase/white-bg/BSG.png";
// @ts-ignore
import LozaFarms from "../../images/purchase/white-bg/LozaFarms.png";
// @ts-ignore
import Hopsteiner from "../../images/purchase/white-bg/Hopsteiner.png";
// @ts-ignore
import CharlesFaram from "../../images/purchase/white-bg/CharlesFaram.png";
// @ts-ignore
import Crosby from "../../images/purchase/white-bg/Crosby.png";
// @ts-ignore
import GoldMalt from "../../images/purchase/white-bg/GoldMalt.png";
// @ts-ignore
import Hollingbery from "../../images/purchase/white-bg/Hollingbery.png";
// @ts-ignore
import HopsCompany from "../../images/purchase/white-bg/HopsCompany.png";
// @ts-ignore
import Agraria from "../../images/purchase/white-bg/Agraria.png";
// @ts-ignore
import HopsConnect from "../../images/purchase/white-bg/HopsConnect.png";
// @ts-ignore
import HopsDirect from "../../images/purchase/white-bg/HopsDirect.png";
// @ts-ignore
import HopStore from "../../images/purchase/white-bg/HopStore.png";
// @ts-ignore
import HVG from "../../images/purchase/white-bg/HVG.png";
// @ts-ignore
import JM from "../../images/purchase/white-bg/JM.png";
// @ts-ignore
import Katoaoka from "../../images/purchase/white-bg/Katoaoka.png";
// @ts-ignore
import Mckinnon from "../../images/purchase/white-bg/Mckinnon.png";
// @ts-ignore
import Mill95 from "../../images/purchase/white-bg/Mill95.png";
// @ts-ignore
import RoyFarms from "../../images/purchase/white-bg/RoyFarms.png";
// @ts-ignore
import NYHG from "../../images/purchase/white-bg/NYHG.png";
// @ts-ignore
import Pekko from "../../images/purchase/white-bg/Pekko.png";
// @ts-ignore
import YakimaChiefHops from "../../images/purchase/white-bg/YakimaChiefHops.png";
// @ts-ignore
import YQH from "../../images/purchase/white-bg/YQH.png";
// @ts-ignore
import Dematech from "../../images/purchase/white-bg/Dematech.png";
export const distributorItemsFirstRow = [
    {
        country: "CANADA",
        backgroundImage: CanadaBackground,
        hopBug: HopImage,
        hops: [
            {
                alt: "Brew CULTURE",
                filename: "BrewCulture.png",
                to: "https://brewculture.com/product/151",
                img: BrewCulture,
            },
            {
                alt: "CHARLES FARAM",
                filename: "CharlesFaram.png",
                to: "https://charlesfaram.co.uk/",
                img: CharlesFaram,
            },
            {
                alt: "HOPS CONNECT",
                filename: "HopsConnect.png",
                to: "https://hopsconnect.com/",
                img: HopsConnect,
            },
            {
                alt: "YAKIMA CHIEF HOPS",
                filename: "YakimaChiefHops.png",
                to: "https://shop.yakimachief.com/amarillo.html",
                img: YakimaChiefHops,
            },
        ],
    },
    {
        backgroundImage: AmericaBackground,
        country: "USA",
        hopBug: HopImage,
        hops: [
            {
                alt: "BSG CraftBrewing",
                filename: "BSG.png",
                to: "https://bsgcraftbrewing.com/hops-american-hops-amarillo",
                img: BSG,
            },
            {
                alt: "Charles Faram",
                filename: "CharlesFaram.png",
                to: "https://charlesfaram.co.uk/",
                img: CharlesFaram,
            },
            {
                alt: "CROSBY HOP FARM",
                filename: "Crosby.png",
                to: "https://crosbyhops.com/",
                img: Crosby,
            },
            {
                alt: "BATH-HAAS GROUP",
                filename: "Bathhaas.png",
                to: "https://www.barthhaas.com/en/hop-varieties/amarillo-vgxp01-c-v",
                img: Bathhaas,
            },
            {
                alt: "YQH Yakima Quality Hops",
                filename: "YQH.png",
                to: "https://www.yakhops.com/all-products/centennial-hop-pellets-22-lb-h7e66-9pcc8-c2dk8-s4jmf-gra89",
                img: YQH,
            },
            {
                alt: "YAKIMA CHIEF HOPS",
                filename: "YakimaChiefHops.png",
                to: "https://shop.yakimachief.com/amarillo.html",
                img: YakimaChiefHops,
            },
            {
                alt: "HOPS DIRECT",
                filename: "HopsDirect.png",
                img: HopsDirect,
                to: "https://hopsdirect.com/collections/pellet-hops/products/amarillo-2019-pellets?variant=31786921721993",
            },
            {
                alt: "NY HOP GUILD",
                filename: "NYHG.png",
                img: NYHG,
                to: "https://www.nyhopguild.com",
            },
            {
                alt: "HOLLINGBERY",
                filename: "Hollingbery.png",
                img: Hollingbery,
                to: "https://hollingberyandson.com/",
            },
            {
                alt: "MILL95",
                filename: "Mill95.png",
                img: Mill95,
                to: "https://mill95hops.com/",
            },
            {
                alt: "Roy Farms",
                filename: "RoyFarms.png",
                img: RoyFarms,
                to: "http://royfarms.com/",
            },
            {
                alt: "Hopsteiner",
                filename: "Hopsteiner.png",
                img: Hopsteiner,
                to: "https://www.hopsteiner.com",
            },
        ],
    },
    {
        country: "CENTRAL AMERICA",
        backgroundImage: CentralAmericaBackgound,
        hopBug: HopImage,
        hops: [
            {
                alt: "Loza Farms",
                filename: "LozaFarms.png",
                to: "https://www.instagram.com/lozafarms/?hl=en",
                img: LozaFarms,
            },
            {
                alt: "BSG CraftBrewing",
                filename: "BSG.png",
                to: "https://bsgcraftbrewing.com/hops-american-hops-amarillo",
                img: BSG,
            },
            {
                alt: "BATH-HAAS GROUP",
                filename: "Bathhaas.png",
                to: "https://www.barthhaas.com/en/hop-varieties/amarillo-vgxp01-c-v",
                img: Bathhaas,
            },
            {
                alt: "Hopsteiner",
                filename: "Hopsteiner.png",
                img: Hopsteiner,
                to: "https://www.hopsteiner.com",
            },
            {
                alt: "HOLLINGBERY",
                filename: "Hollingbery.png",
                img: Hollingbery,
                to: "https://hollingberyandson.com/",
            },
        ],
    },
    {
        country: "SOUTH AMERICA",
        backgroundImage: SABackground,
        hopBug: HopImage,
        hops: [
            {
                alt: "PEKKO",
                filename: "Pekko.png",
                to: "https://www.pekko.com.ar/en/",
                img: Pekko,
            },
            {
                alt: "HOPS COMPANY",
                filename: "HopsCompany.png",
                to: "https://hopscompany.com/",
                img: HopsCompany,
            },
            {
                alt: "Agrária Malte",
                filename: "AgrariaMalte.png",
                to: "https://www.agraria.com.br/malte/produtos/4/lupulos",
                img: Agraria,
            },
        ],
    },
    {
        country: "EU",
        backgroundImage: EUBackground,
        hopBug: HopImage,
        hops: [
            {
                alt: "YAKIMA CHIEF HOPS",
                filename: "YakimaChiefHops.png",
                to: "https://shop.yakimachief.com/amarillo.html",
                img: YakimaChiefHops,
            },
            {
                alt: "CROSBY HOP FARM",
                filename: "Crosby.png",
                to: "https://crosbyhops.com/",
                img: Crosby,
            },
            {
                alt: "BATH-HAAS GROUP",
                filename: "Bathhaas.png",
                to: "https://www.barthhaas.com/en/hop-varieties/amarillo-vgxp01-c-v",
                img: Bathhaas,
            },
            {
                alt: "HOPSTORE",
                filename: "HopStore.png",
                to: "https://www.hopstore.fr/en/",
                img: HopStore,
            },
            {
                alt: "JM HOPS",
                filename: "JM.png",
                to: "https://jmhopscz.com/en/",
                img: JM,
            },
            {
                alt: "CHARLES FARAM",
                filename: "CharlesFaram.png",
                to: "https://charlesfaram.co.uk/",
                img: CharlesFaram,
            },
            {
                alt: "HVG",
                filename: "HVG.png",
                to: "https://hvg-germany.de/en/",
                img: HVG,
            },
            {
                alt: "Hopsteiner",
                filename: "Hopsteiner.png",
                img: Hopsteiner,
                to: "https://www.hopsteiner.com",
            },
        ],
    },
    {
        country: "AFRICA",
        backgroundImage: AfricaBackground,
        hopBug: HopImage,
        hops: [
            {
                alt: "Dematech",
                filename: "Dematech.png",
                to: "https://dematechsa.com",
                img: Dematech,
            },
        ],
    },
    {
        country: "AUSTRALIA",
        backgroundImage: AustraliaBackground,
        hopBug: HopImage,
        hops: [
            {
                alt: "YAKIMA CHIEF HOPS",
                filename: "YakimaChiefHops.png",
                to: "https://shop.yakimachief.com/amarillo.html",
                img: YakimaChiefHops,
            },
            {
                alt: "BATH-HAAS GROUP",
                filename: "Bathhaas.png",
                to: "https://www.barthhaas.com/en/hop-varieties/amarillo-vgxp01-c-v",
                img: Bathhaas,
            },
            {
                alt: "BINTANI AUSTRALIA",
                filename: "Bitani.png",
                img: Bitani,
                to: "https://www.bintani.com.au/HUAMAT5/US-Amarillo%C2%AEVGXP01c.v.-%28Tropical-Expression%29-T90-x-5Kg/pd.php",
            },
        ],
    },
    {
        country: "ASIAN PACIFIC",
        backgroundImage: AsianBackground,
        hopBug: HopImage,
        hops: [
            {
                alt: "BARTH-HAAS GROUP",
                filename: "Bathhaas.png",
                to: "https://www.barthhaas.com/en/hop-varieties/amarillo-vgxp01-c-v",
                img: Bathhaas,
            },
            {
                alt: "BREW NATION",
                filename: "BrewNation.png",
                to: "www.brewnation.in",
                img: BrewNation,
            },
            {
                alt: "GOLD MALT",
                filename: "GoldMalt.png",
                to: "https://goldmalt.com.vn/",
                img: GoldMalt,
            },
            {
                alt: "KATAOKA",
                filename: "Katoaoka.png",
                to: "https://www.kataoka.com/en/",
                img: Katoaoka,
            },
            {
                alt: "McKinnon International Inc.",
                filename: "Mckinnon.png",
                to: "http://mckinnoninternational.com/japanese/index.htm",
                img: Mckinnon,
            },
            {
                alt: "YAKIMA CHIEF HOPS",
                filename: "YakimaChiefHops.png",
                to: "https://shop.yakimachief.com/amarillo.html",
                img: YakimaChiefHops,
            },
        ],
    },
];
